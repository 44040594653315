<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'AccountBalance',
  data () {
    return {}
  },
  created () {
    console.log('AccountBalance')

    this.type = this.$store.state.member.memberCenter.status
		 console.log(this.type)
    if (this.type === 4 || this.type === 5 || this.type === 6) {
      // 待开户 | 开户失败
      this.$router.push('/user/account/balance/open-account')
    }
  },
}
</script>
